export default [
  {
    name: "Daniel Marbach",
    role: "(Msc) CEO",
    image: "daniel.jpg",
    paragraphs: [
      "Worked at various auditing and consulting firms as a consultant/partner.",
      "Deals with the administrative/management tasks and IP related issues.",
    ],
  },
  {
    name: "István SZŐCS",
    role: "(MSc in Chemical Engineering, Auditor of Environmentally Aware Management Systems, Ph.D. on Environment Protection) Inventor",
    image: "istvan.jpg",
    paragraphs: [
      "His life and hobby is the R&D for firefighting technologies, materials and equipment.",
      "In the last 30 years, he obtained 75 granted Patents.",
      "He invented the Impulse Fire Extinguishing technology, and the Self Expanding Foam (SEF) under the trademark of Pressurized Instant Foam (Pi Foam) Storage Tank Fire Protection Technology (formerly called Foam Fatale).",
      'The thesis of his Ph.D. (summa cum laude) graduation was "Research & Development of Environment-Friendly Technology for the Fire Protection of Hydrocarbon Storage Tanks".',
    ],
  },
  // {
  //   name: "László LOVAS",
  //   role:
  //     "(MSc Industrial Production Technologies/Materials Technology, Fire System Engineering Design)",
  //   image: "laszlo.jpg",
  //   paragraphs: [
  //     "He worked as Plant Design Engineer at one of the largest oil and gas engineering companies in the Central-Eastern European region. As a lead engineer, he participated in and managed some of the key projects of the regional oil and gas industry. His competencies in Industrial Firefighting:",
  //     "Industrial fire prevention, protection, and emergency systems, storage-tank operators; hydrocarbon-storage security, fire-resistant foam concentrate.",
  //     "Oil and Gas: plant design, plant engineering, piping standards, feasibility study, conceptual design, Basic Plans (BEPs), FEED documentation, process modeling, and other technological calculations.",
  //   ],
  // },
  {
    name: "Ferenc FARKAS",
    role: "(MSc Economics, JD), Senior International Advisor",
    image: "ferenc.jpg",
    paragraphs: [
      "Multiple decades of business advisory and management experience in the oil & gas business.",
      "He acted as a director, especially in the area of upstream business development and established many successful international projects and strategic alliances in the upstream business worldwide.",
    ],
  },
  {
    name: "Gabriella PAP",
    role: "(LLM, MBA), Project Manager",
    image: "gabriella.jpg",
    paragraphs: [
      "Experienced executive with 20 years of professional background in the energy industry. Highly skilled in Mergers & Acquisitions (M&A), Corporate Governance, Leadership, Renewable Energy, and Project Management.",
      "Spent six years in a leadership role at E.ON working in the field of international and domestic gas sales. Was later responsible for the gas/electricity and M&A areas as deputy CEO at one of the biggest state-owned energy companies, controlling a budget in excess of EUR3bn.",
    ],
  },
  {
    name: "András T. PELLER",
    role: "(Msc in Disaster Management, Business Administration, Technical Engineering) COO",
    image: "andras2.jpg",
    paragraphs: [
      "He built up an IT company which he was managing for more than ten years. As a seasoned and successful leader in the IT sector, he learned to manage a middle-sized company effectively in a highly competitive international market.",
      "Being an enthusiast for technological advancements and for research and development, he is the leader of operations of the R&D division.",
    ],
  },
  {
    name: "Liza JANOVICZ",
    role: "(MSc HR Management and Psychology), Key Account Manager",
    image: "liza.jpg",
    paragraphs: [
      "Graduated from the University of Buckingham in HR Management and pursues post-graduate studies at the Harvard Business School in Management and the Harvard Kennedy School of Government in the field of International Relations.",
      "Has strong communication and interpersonal skills with aptitude in building relationships with professionals of all nations and organizational levels.",
      "Develops trust relationships with a portfolio of our major clients.",
      "Is responsible for maintaining long-term key customers by comprehending their requirements.",
      "Expands the relationships with existing customers by continuously proposing solutions that meet their objectives.",
      "Has native proficiency language skills in French and working proficiency in English.",
    ],
  },
  // {
  //   name: "Viktor KIS",
  //   role: "Founder-Owner, Investor",
  //   image: "viktor.jpg",
  //   paragraphs: [
  //     "Eager to add maximum value to the business in full compliance with company culture, strategy, and regulations using a unique combination of twenty years’ experience in multinational banking and corporate sector coupled with a solid management background.",
  //     "Holds a Ph.D. (summa cum laude) degree in finance and he has written two books under a pen name about his scientific specialty.",
  //     'He is a <a href="https://mta.hu/koztestuleti_tagok?PersonId=18678" target="blank">Member</a> of the Public Body of the Hungarian Academy of Sciences.',
  //   ],
  // },
];
