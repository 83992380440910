import React from "react";
import { withPrefix } from "gatsby";
import Layout from "../components/Layout";
import sfprd from "../images/sfprd-logo-big.png";
import members from "../lib/team";
import cvs from "../lib/engineer_cvs";
import "../styles/about.css";

function team() {
  return (
    <Layout>
      <main>
        <div className="about__company__wrapper">
          <a href="https://sfprd.com" target="blank">
            <img src={sfprd} alt="sfprd logo" />
          </a>
          <article>
            <p>
              Our mission is to create a new level of awareness about large
              industrial fire disasters by introducing new, cost-effective ways
              of controlling, managing and suppressing fires.
            </p>
            <p>
              At SFPRD, a dedicated team of fire-protection, chemical, HSE, and
              mechanical engineers, as well as safety-protocol experts, work
              relentlessly to address seemingly unsolvable fire-safety
              challenges. Presently, we are primarily focused on developing
              higher foam application-capacity solutions as well as adjustments
              for installations located in harsh climates – conditions that
              often make proper fire-safety coverage unsustainably expensive. We
              have created an unparalleled system for putting out fires in
              storage tanks and dikes.
            </p>
            <p>
              To the leading oil, chemical, pharmaceutical and vegetable-oil
              companies, as well as tank-construction firms: We have done our
              part to make your sector safer and less harmful to the
              environment. Swiss Fire Protection AG wants to make the world a
              place that is free from storage-tank infernos and quickly
              escalating catastrophes.
            </p>
            <p> Now it is your turn.</p>
          </article>
        </div>
        <div className="about__team__wrapper">
          <h1>Dedicated key team members</h1>
          <div className="about__member__container">
            {members.map((member) => {
              return (
                <div className="about__member">
                  <div>
                    <img
                      src={withPrefix(`/images/team/${member.image}`)}
                      alt=""
                    />
                  </div>
                  <div>
                    <p>
                      <strong>{member.name}</strong>,{" "}
                      <p
                        style={{ display: "inline" }}
                        dangerouslySetInnerHTML={{ __html: member.role }}
                      />
                    </p>
                    <ul>
                      {member.paragraphs.map((pg) => (
                        <li dangerouslySetInnerHTML={{ __html: pg }}></li>
                      ))}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
          <h1>Anonymized CVs of the Engineering Team in PDF.</h1>
          <div className="cv__container">
            <div
              style={{
                textAlign: "center",
                listStyle: "none",
                marginBottom: 50,
              }}
            >
              {cvs.map((cv) => {
                return (
                  <p style={{ padding: 5, fontSize: "1.2rem" }}>
                    <a
                      href={withPrefix(`/engineer_cvs/${cv.pdf}.pdf`)}
                      target="blank"
                    >
                      {cv.name}
                    </a>
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default team;
