export default [
  {
    name: "Lead Mechanical Engineer",
    pdf: "2-Lead-Mechanical-Eng-c",
  },
  {
    name: "Lead Engineer in Research and Development",
    pdf: "3-Lead-RnD-Eng-c",
  },
  {
    name: "Lead Process Engineer",
    pdf: "4-Lead-Process-Eng-c",
  },
  {
    name: "Senior Electrical and Control Engineer",
    pdf: "5-Snr-Electrical-Eng-c",
  },
  {
    name: "Senior Mechanical Engineer",
    pdf: "6-Snr-Mechanical-Eng-c",
  },
  {
    name: "Fire Safety and Research Engineer",
    pdf: "7-Fire-Safety-Eng-c",
  },
  {
    name: "Junior Mechanical Engineer",
    pdf: "8-Jnr-Mechanical-Eng-c",
  },
];
